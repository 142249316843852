module.exports = {
  // markServicesAsAvailable:
  //   'To make your services visible to potential patients, mark them as visible in online registration.',
  clickHereToGoToServices: 'Click here to navigate to services.',
  clickToCopy: 'Click to copy the link',
  createMeeting: 'Confirm meeting request',
  createNewPatient: 'Create new patient',
  meetingHasBeenCreated: 'Meeting has been created',
  meetingHasBeenRejected: 'Meeting has been rejected',
  meetingHasBeenDeleted: 'Meeting has been deleted',
  meetingRequest: 'Meeting request',
  meetingRequestDetails: 'Meeting details',
  contactWithPatient:
    'Please contact the patient to arrange a new appointment. After a booking is declined, the patients details can be found in the booking table. You can also enable online payments, which automatically confirm the appointment, guaranteeing the availability of the therapist.',
  meetingRequests: 'Meeting requests to confirm',
  minutesBetweenMeetings: 'Number of minutes break between meetings.',
  sendReceptionNewMeetingRequestEmail:
    'Send an email about a new meeting to the reception',
  sendReceptionNewMeetingRequestEmailDescription:
    'Decide if all employees with role reception will be notified via email of new meeting request',
  sendAdminNewMeetingRequestEmail:
    'Send an email about a new meeting to the admins',
  sendAdminNewMeetingRequestEmailDescription:
    'Decide if all employees with role admin will be notified via email of new meeting request',
  sendReceptionNewMeetingRequestMobileNotification:
    'Send a notification about a new meeting to the reception',
  sendReceptionNewMeetingRequestMobileNotificationDescription:
    'Decide if all employees with role reception will be notified via notification on their mobile app of new meeting request',
  sendAdminNewMeetingRequestMobileNotification:
    'Send a notification about a new meeting to the admins',
  sendAdminNewMeetingRequestMobileNotificationDescription:
    'Decide if all employees with role admin will be notified via notification on their mobile app of new meeting request',
  automaticConfirmNewMeetingRequest:
    'Automatically accept meeting requests when payment is made in-office',
  automaticConfirmNewMeetingRequestDescription:
    'Select this option to automatically accept meeting requests',
  minutesBetweenMeetingsDescription:
    'This number of minutes will be added after the meeting before another meeting is possible.',
  noMeetingRequests: 'No meeting requests',
  przelewy24ConfigUpdated: 'Przelewy24 config updated',
  patient: 'Patient',
  pickRoom: 'Select room',
  noMeetingRequestsHeader: 'No new meeting requests',
  practiceSlug: 'The name of your practice in the registration form address.',
  canChangeThisSlug:
    'You have the option to modify the form address suffix, provided it is accessible and suits your office"You have the option to modify the form address suffix, provided it is accessible and suits your office',
  privacyPolicy: 'Link to privacy policy',
  registrationEnabled: 'Enable registration',
  registrationInfo:
    'After enabling online registration, your patients will be able to book appointments with you through an online form. Also, select the services available for online registration by marking them accordingly in',
  rememberToSetWorkingTimes:
    'Please remember to specify the working hours for each person who is to be available for online registration. For more information, please refer to ',
  serviceSettings: 'service settings.',
  ourInstructions: ' our instructions.',
  registrationLink: 'Link to your booking page',
  room: 'Room',
  sureToConfirmMeeting: 'Do you confirm the meeting request?',
  termsOfUse: 'Link to terms of use',
  thereAreSimilarPatients:
    'There is already a patient with this data in your practice. We selected automatically similiar, existing patient. Please verify it in dropdown or select an option for creating new patient',
  title: 'Online registration',
  configUpdated: 'Registration configuration updated',
  enabled: 'Online registration is enabled',
  disabled: 'Online registration is disabled',
  registrationUrl: 'Your registration form address for patients',
  maximumMeetingRequestCancellationTime:
    'Max meeting request cancellation time',
  maximumMeetingRequestCancellationTimeDescription:
    'Define how much time before the meeting your clients can cancel it. In the case of Przelewy24 payment, their money will also be refunded',
  isCancelledMeetingFreeSlot:
    'Should a canceled appointment be made available again as an available time slot in the online registration system for other patients?',
  isCancelledMeetingFreeSlotBottomText:
    'If you select No, the calendar of free time slots will not show canceled appointments for new patients.',
  isCancelledMeetingFreeSlotTitle: 'Cancellation settings',
  isCancelledMeetingFreeSlotLeftDescription:
    'Define how the system should handle canceled meetings.',
  clickToCopyRegistrationUrl:
    'Click here to open your registration form. If you cannot see the terms, please refer to ',
  copyIFrameText: 'Click to copy iFrame',
  iFrameTextCopied: 'iFrame text has been copied',
  iFrameSourceCodeDescription:
    "Do you want to add this form to your website? Click the text below to copy it, then paste it into your website's code.",
  onlineRegistrationStatusTitle: 'Online Registration Status',
  onlineRegistrationStatusDescription:
    'Here you can set whether and at what address you will make online registration available.',
  timeSettingsTitle: 'Time Settings',
  timeSettingsDescription:
    'Here you can specify the lead time before and after the appointment.',
  notificationSettingsTitle: 'Notifications about new meetings',
  notificationSettingsDescription:
    'Set how notifications about new meetings will be sent',
  termsSettingsTitle: 'Terms Settings',
  termsSettingsDescription:
    'Here you can paste the link to the online registration terms and your Privacy Policy, which customers will see in the online registration.',
  parseTermsOfUseLinkHere:
    'Paste the link to the online registration terms, which is available on your website, here.',
  parsePrivacyPolicyLinkHere:
    'Paste the link to your Privacy Policy, which is available on your website, here.',
  registrationLinkCopied: 'Online registration link copied',
  minimumPrecedingMinutes: 'Minimum lead time before the meeting',
  minimumPrecedingMinutesDescription:
    'Eg. if the lead time is 8h, then at 10am a patient will be able to book a meeting at 6pm onwards. 1 and 2 days will show free slots from the beginning of next day or two',
  maximumPrecedingMinutes: 'Maximum preceding time for meetings',
  maximumPrecedingMinutesDescription:
    'Specify the maximum available time period for patients to schedule a meeting in advance',
  roundSlotTimeTo: 'Round meeting time to:',
  roundToDescription:
    'This number of minutes determines the starting times of potential meetings. It takes into account therapists working hours and scheduled appointments in the calendar.',
  quarter: 'Quarter',
  exactSlot: 'Round off the start of new meetings',
  exactSlotDescription: 'Propose new meetings rounded to the given value',
  preciseSearch: 'Precise search',
  preciseSearchDescription:
    'This option finds the maximum number of available dates in the calendar by disabling rounding in some cases. It may result in less standard hours, e.g. 10:47 instead of 10:50',
  halfHour: 'Half an hour',
  hour: 'An hour',
  none: 'None',
  twoHours: 'Two hours',
  threeHours: 'Three hours',
  fourHours: 'Four hours',
  fiveHours: 'Five hours',
  details: 'Details',
  reject: 'Reject',
  sureToRejectMeeting: 'Are you sure you want to reject this meeting?',
  sendSMSUponRejectMeeting:
    'Notify the patient via SMS about meeting rejection',
  createdAt: 'Request since',
  rejectButtonInfo:
    'Once the appointment is declined, the patient will receive a notification. You can contact them to schedule a new appointment.',
  confirmInfo:
    'Once the meeting request is confirmed, it will appear in your calendar and we will send a confirmation to the patient.',
  patientNote: 'Patient notes',
  day: '{count} day | {count} days',
  logoRegistrationUpload: 'Form Logo',
  logoRegistrationUploadDescription:
    'Logo registered on the form. The same logo visible on devices.',
  logoSet: 'Logo has been set',

  tabs: {
    reservations: 'Reservations',
    settings: 'Settings',
    registration: 'Registration in Przelewy24',
    payments: 'Payments'
  },

  // Maximum Proceeding Options
  oneWeek: 'One week',
  twoWeeks: 'Two weeks',
  threeWeeks: 'Three weeks',
  fourWeeks: 'Four weeks',
  fiveWeeks: 'Five weeks',
  sixWeeks: 'Six weeks',
  sevenWeeks: 'Seven weeks',
  eightWeeks: 'Eight weeks',
  nineWeeks: 'Nine weeks',
  tenWeeks: 'Ten weeks',
  elevenWeeks: 'Eleven weeks',
  twelveWeeks: 'Twelve weeks'
}
